<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.new_lesson') }}</h3>
      <div class="float-right">
        <router-link :to="{'name':'lessons-list', 'params': {masterClassId: this.masterClassId}}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card>

            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.lesson.name') }}</label>
              <p>
                <input v-model="formEdit.name" class="form-control">
              </p>

              <label>{{ $t('page.description') }}</label>
              <p>
                <textarea v-model="formEdit.description" class="form-control"></textarea>
              </p>

              <label>{{ $t('page.status') }}</label>
              <p>
                <vue-select

                    v-model="formEdit.status"
                    :options="statuses" :reduce="status => status.value"
                    label="text"
                ></vue-select>
              </p>

              <label>{{ $t('page.lesson.number') }}</label>
              <p>
                <input v-model="formEdit.number" class="form-control" type="number">
              </p>

              <label>{{ $t('page.lesson.length') }}</label>
              <p>
                <input v-model="formEdit.length" class="form-control" type="number">
              </p>

              <label>{{ $t('page.lesson.scoreVideo') }}</label>
              <p>
                <input v-model="formEdit.scoreVideo" class="form-control">
              </p>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-6">
          <b-card>
            <b-card-header><b>{{ $t('page.lesson.movie') }}</b></b-card-header>
            <b-card-body>
              <label>{{ $t('page.lesson.movie') }}</label>
              <p>
                <input v-model="formEdit.movie" class="form-control">
              </p>
              <vimeo v-if="formEdit.movie" :vimeo-id="formEdit.movie" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithSubtitles') }}</label>
              <p>
                <input v-model="formEdit.movieWithSubtitles" class="form-control">
              </p>

              <vimeo v-if="formEdit.movieWithSubtitles" :vimeo-id="formEdit.movieWithSubtitles" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithSignLanguage') }}</label>
              <p>
                <input v-model="formEdit.movieWithSignLanguage" class="form-control">
              </p>

              <vimeo v-if="formEdit.movieWithSignLanguage" :vimeo-id="formEdit.movieWithSignLanguage" title=""></vimeo>

              <label>{{ $t('page.lesson.movieWithAudioDescription') }}</label>
              <p>
                <input v-model="formEdit.movieWithAudioDescription" class="form-control">
              </p>

              <vimeo v-if="formEdit.movieWithAudioDescription" :vimeo-id="formEdit.movieWithAudioDescription" title=""></vimeo>

            </b-card-body>
          </b-card>
        </div>

      </div>
      <div class="buttons float-right">
        <b-button type="button" @click="changeData()" variant="success" class="mr-1">{{ $t('actions.save') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { VueSelect } from "vue-select";
import "vue-select/dist/vue-select.css";
import Vimeo from "@/components/vimeo/Vimeo.vue";

export default {
  props: ['apiKey'],
  components: {
    VueSelect,
    Vimeo
  },
  data() {
    return {
      masterClassId: null,
      formEdit: {},
      isEdit: false,
      statuses: [
        {value: 'active', text: 'Active'},
        {value: 'inactive', text: 'Inactive'},
        {value: 'bonus', text: 'Bonus'},
        {value: 'deleted', text: 'Deleted'},
      ],
    };
  },
  computed: {
  },
  created() {
    this.masterClassId = this.$route.params.masterClassId;
  },
  methods: {
    changeData() {
      const url = '/lesson';
      this.formEdit.masterClassId = this.masterClassId;
      this.formEdit.number = parseInt(this.formEdit.number);
      this.formEdit.length = parseInt(this.formEdit.length);

      axios.post(url, this.formEdit)
          .then(() => {
            alertify.success('Lekcja została dodana');
            this.$router.push({name: 'lessons-list', params: {masterClassId: this.masterClassId}});
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

